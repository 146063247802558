export enum SummariesStatus {
  pending = 'pending',
  processing = 'processing',
  not_verified = 'not_verified',
  verified = 'verified',
  done = 'done',
  error = 'error',
  pause = 'pause',
}

export enum SummarySidebarForms {
  verify = 'verify',
  add = 'add',
  edit = 'edit',
}

export enum FrequencyType {
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly',
}

export enum AvailableServicesEnum {
  GOOGLE_ADS = 'GOOGLE_ADS',
  BING = 'BING_ADS',
  FACEBOOK = 'FACEBOOK_ADS',
}

export enum DateOptionsEnum {
  FIRST_BUSINESS_DAY = 'first_business_day',
  BUSINESS_DAY_OFFSET = 'business_day_offset',
  DAY_AFTER_GENERATION = 'day_after_generation',
}

export enum VisibilityType {
  Card = 'card',
  Widget = 'widget',
}
