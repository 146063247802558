const LANDING_PAGE = {
  de: 'https://de.sembot.com/',
  en: 'https://sembot.com',
  pl: 'https://pl.sembot.com',
};
const GUIDE_PAGE = {
  en: 'https://help.sembot.com/en/support/solutions/articles',
  pl: 'https://help.sembot.com/pl/support/solutions/articles',
};

class CommonUrlsConfig {
  chatOpenAi = 'https://chat.openai.com';
  getRandomPhoto = 'https://api.unsplash.com/photos/random'; // Images service
  sembotWhiteLabelCSS = 'https://sembot.io/css';
  sembotWebsite = 'https://sembot.com';

  // URLs depending on the current language
  en = {
    activeService: `${LANDING_PAGE.en}/active-service`,
    allInOne: `${LANDING_PAGE.en}/all-in-one`,
    howToUseChatGptWithSembot: `${LANDING_PAGE.en}/sembot-chat-gpt-plug-in`,
    landingPage: LANDING_PAGE.en,
    privacyPolicy: `${LANDING_PAGE.en}/sembot-privacy-policy`,
    termsAndconditions: `${LANDING_PAGE.en}/sembot-terms-of-service`,

    projectProducts: `${GUIDE_PAGE.en}/101000508296-edit-products-data-optimization`,
    projectRules: `${GUIDE_PAGE.en}/101000519190-rule-settings`,
    projectFeed: `${GUIDE_PAGE.en}/101000508284-adding-your-product-data`,
    projectFeedSecondary: `${GUIDE_PAGE.en}/101000508286-choose-products-and-mapping-for-output-feed`,
    projectBrands: `${GUIDE_PAGE.en}/101000519394-brand-module`,
    project_categories: `${GUIDE_PAGE.en}/101000519393-categories-module`,
    asset_library: `${GUIDE_PAGE.en}/101000519400-asset-library`,
    createNewStrategy: `${GUIDE_PAGE.en}/101000508297-generate-your-first-campaign`,
    yourStrategy: `${GUIDE_PAGE.en}/101000508297-generate-your-first-campaign`,
    priceMonitor: `${GUIDE_PAGE.en}/101000508480-use-price-watch-monitor`,
    organicMonitor: `${GUIDE_PAGE.en}/101000519395-organic-monitor`,
    productAdsMonitor: `${GUIDE_PAGE.en}/101000519397-product-ads-monitor`,
    textAdsMonitor: `${GUIDE_PAGE.en}/101000519396-monitor-reklam-tekstowych`,
    connections: `${GUIDE_PAGE.en}/101000508292-create-ads-platform-connection`,
    comparators: `${GUIDE_PAGE.en}/101000509034-what-is-css-`,
    visibilityAudit: `${GUIDE_PAGE.en}/101000508299-audit`,
    summaries: `${GUIDE_PAGE.en}/101000508299-summaries`,
  };

  pl = {
    activeService: `${LANDING_PAGE.pl}/sembot-active-service`,
    allInOne: `${LANDING_PAGE.pl}/sembot-all-in-one`,
    howToUseChatGptWithSembot: `${LANDING_PAGE.pl}/sembot-chat-gpt-plug-in`,
    landingPage: LANDING_PAGE.pl,
    privacyPolicy: `${LANDING_PAGE.pl}/polityka-prywatnosci`,
    termsAndconditions: `${LANDING_PAGE.pl}/regulamin`,

    projectProducts: `${GUIDE_PAGE.pl}/101000508296-edytuj-produkty-optymalizacja-danych`,
    projectRules: `${GUIDE_PAGE.pl}/101000519190-zarządzanie-regułami`,
    projectFeed: `${GUIDE_PAGE.pl}/101000508284-dodaj-dane-produkt%C3%B3w`,
    projectFeedSecondary: `${GUIDE_PAGE.pl}/101000508286-wybierz-produkty-oraz-mapping-dla-pliku-produktowego-wychodz%C4%85cego`,
    projectBrands: `${GUIDE_PAGE.pl}/101000519394-ustawienia-marek`,
    project_categories: `${GUIDE_PAGE.pl}/101000519393-ustawienia-kategorii`,
    asset_library: `${GUIDE_PAGE.pl}/101000519400-biblioteka-zasobów`,
    createNewStrategy: `${GUIDE_PAGE.pl}/101000508297-wygeneruj-swoj%C4%85-pierwsz%C4%85-kampani%C4%99`,
    yourStrategy: `${GUIDE_PAGE.pl}/101000508297-wygeneruj-swoj%C4%85-pierwsz%C4%85-kampani%C4%99`,
    priceMonitor: `${GUIDE_PAGE.pl}/101000508480-skorzystaj-z-monitora-cen`,
    organicMonitor: `${GUIDE_PAGE.pl}/101000519395-monitor-wyników-organicznych`,
    productAdsMonitor: `${GUIDE_PAGE.pl}/101000519397-monitor-reklam-produktowych`,
    textAdsMonitor: `${GUIDE_PAGE.pl}/101000519396-text-ads-monitor`,
    connections: `${GUIDE_PAGE.pl}/101000508292-utw%C3%B3rz-po%C5%82%C4%85czenie-z-platform%C4%85-reklamow%C4%85`,
    comparators: `${GUIDE_PAGE.pl}/101000509034-co-to-jest-css-`,
    visibilityAudit: `${GUIDE_PAGE.pl}/101000508299-audyt`,
    summaries: `${GUIDE_PAGE.pl}/101000508299-summaries`,
  };
}

export default new CommonUrlsConfig();
