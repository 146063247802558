import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TaskPriorityEnum } from 'src/app/dashboard/tasks/tasks.enum';
import { EditableColumnsComponent } from '../../sem-table/table-display/columns-switch/columns/ColumnsComponent.interface';
import { BADGE_SETS } from './badge-sets';
import { BadgeInterface, BadgeTypeEnum, SetType } from './badge.model';
import { SummariesStatus } from '../../../dashboard/project/summaries/summaries.enum';

const DEFAULT_IS_OUTLINED = false;
const DEFAULT_TYPE = BadgeTypeEnum.dark;

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent {
  @Input() isOutlined: boolean = DEFAULT_IS_OUTLINED;
  @Input() translateKey!: string;
  @Input() type: BadgeTypeEnum = DEFAULT_TYPE;
  edit = false;
  readonly prioritiesList = Object.values(TaskPriorityEnum);
  valueKey!: string;
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();
}

@Component({
  selector: 'app-badge-set',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeSetComponent implements EditableColumnsComponent {
  isOutlined: boolean = DEFAULT_IS_OUTLINED;
  type: BadgeTypeEnum = DEFAULT_TYPE;
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();

  edit = false;

  valueKey!: string;
  readonly prioritiesList = Object.values(TaskPriorityEnum);
  @Input() set!: SetType;
  private defaultTranslateKey: any;

  private _translateKey!: string;

  get translateKey(): string {
    if (this.set === 'summaries-status' && this.valueKey === SummariesStatus.pause) {
      return 'summaries.status.pause';
    }

    return this._translateKey || this.defaultTranslateKey;
  }

  @Input() set translateKey(key: string) {
    this._translateKey = key;
  }

  @Input() set key(key: string) {
    if (!key) {
      console.warn('BadgeSetComponent: Undefined or empty key detected', key);
      return;
    }

    this.valueKey = key;
    const setData = BADGE_SETS[this.set] || null;
    const badgeData: BadgeInterface = (setData && setData[key]) || null;

    if (badgeData) {
      const { isOutlined, translateKey, type } = badgeData;

      isOutlined && (this.isOutlined = isOutlined);
      this.defaultTranslateKey = translateKey || key;
      type && (this.type = type);
    }
  }

  enterEditableMode(): void {
    this.edit = true;
  }

  exitEditableState(): void {
    this.edit = false;
  }
}
