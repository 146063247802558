import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import CommonUrlsConfig from 'src/app/configs/common-urls.config';
import { TableConfigurationInterface } from '../sem-table/models/TableConfigurationInterface.model';
import { ColumnModel } from '../sem-table/table-display/columns-switch/columns/column.model';
import { LocalStorageService, storageKey } from './local-storage.service';

export enum LangEnum {
  en = 'en',
  pl = 'pl',
}

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private readonly translateService = inject(TranslateService);
  private readonly storageHelperService = inject(LocalStorageService);

  get storageLang() {
    return this.translateService.currentLang as LangEnum;
  }

  commonUrls() {
    return CommonUrlsConfig[this.storageLang];
  }

  parseConfig(config: TableConfigurationInterface): TableConfigurationInterface {
    Object.keys(config.columns).forEach((key) => {
      config.columns[key].alias = this.mapColumnAlias(key, config.columns[key]);
    });
    config.actionsButtonSlot &&
      config.actionsButtonSlot.forEach((action) => (action.alias = this.mapActionAlias(action.actionType) || action.alias));
    config.mainToolbarSlot && config.mainToolbarSlot.forEach((action) => (action.alias = this.mapActionAlias(action.alias)));
    config.selectedToolbarSlot &&
      config.selectedToolbarSlot.forEach((action) => (action.alias = this.mapActionAlias(action.actionType) || action.alias));
    config.selectedGloballyToolbarSlot &&
      config.selectedGloballyToolbarSlot.forEach((action) => (action.alias = this.mapActionAlias(action.actionType) || action.alias));

    return config;
  }

  setCustomLang(lang: LangEnum) {
    this.storageHelperService.save(storageKey.locale, lang);
    this.translateService.use(lang);
  }

  getCurrentLanguage(): LangEnum {
    const lang = this.translateService.currentLang || this.translateService.defaultLang;
    return Object.values(LangEnum).includes(lang as LangEnum) ? (lang as LangEnum) : LangEnum.en;
  }

  private mapColumnAlias(key: string, column: ColumnModel) {
    if (this.translateService.instant(`columns.${key}`) !== `columns.${key}`) {
      return this.translateService.instant(`columns.${key}`);
    }
    return column.alias;
  }

  private mapActionAlias(action: string) {
    if (this.translateService.instant(`actions.${action}`) !== `actions.${action}`) {
      return this.translateService.instant(`actions.${action}`);
    }
    return null;
  }
}
