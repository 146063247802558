import { RouterConfigType, SuperiorPermissionEnum } from '../shared/model/router-config.model';

export const maskChar = '@';

const RouterConfig: RouterConfigType = {
  '/': {
    url: '/',
  },

  aiAssistant: {
    permissions: ['chat with assistant'],
    url: '/dashboard/ai-assistant',
    isBetaFeature: true,
  },

  aiAssistantThread: {
    permissions: ['chat with assistant'],
    url: '/dashboard/ai-assistant/{thread}',
  },

  summariesList: {
    permissions: ['list summary template'],
    url: '/dashboard/project/{project}/summaries/list',
  },

  authForgot: {
    url: '/auth/forgot',
  },

  authLogin: {
    url: '/auth/login',
  },

  authRegister: {
    isUnavailableForWhiteLabels: true,
    url: '/auth/register',
  },

  automation: {
    permissions: ['view task trigger', 'edit task trigger', 'store task trigger'],
    url: '/dashboard/automation/start',
    isBetaFeature: true,
  },

  comparator: {
    permissions: ['view comparator'],
    url: '/dashboard/comparators/{comparator}',
  },

  comparatorConfig: {
    permissions: ['view comparator', 'edit comparator'],
    url: '/dashboard/comparators/{comparator}/config',
  },

  comparators: {
    permissions: ['list comparators'],
    url: '/dashboard/comparators/list',
  },

  comparatorShop: {
    permissions: ['view comparator'],
    url: '/dashboard/comparators/{comparator}/shop',
  },

  connections: {
    url: '/dashboard/connections',
  },

  home: {
    url: '/dashboard/home',
  },

  fix: {
    url: '/fix',
  },

  maintenance: {
    url: '/maintenance',
  },

  organicTasks: {
    permissions: ['view monitor'],
    url: '/dashboard/project/{project}/monitor/organic',
  },

  textAdsTasks: {
    permissions: ['view monitor'],
    url: '/dashboard/project/{project}/monitor/text-ads',
  },

  productAdsTasks: {
    permissions: ['view monitor'],
    url: '/dashboard/project/{project}/monitor/product-ads',
  },

  priceComparison: {
    permissions: ['view monitor'],
    url: '/dashboard/project/{project}/monitor/shopping',
  },

  news: {
    url: '/dashboard/news',
  },

  profileEdit: {
    url: '/dashboard/profile/edit',
  },

  project: {
    permissions: ['view project'],
    url: '/dashboard/project/{project}',
  },

  projectAdd: {
    permissions: ['store project'],
    url: '/dashboard/project/add',
  },

  projectAudit: {
    permissions: ['view audit'],
    url: '/dashboard/project/{project}/audit',
  },

  projectCampaigns: {
    permissions: ['list campaigns'],
    url: '/dashboard/project/{project}/campaign',
  },

  projectCampaignStatistics: {
    permissions: ['list campaigns'],
    url: '/dashboard/project/{project}/campaign-statistics',
  },

  projectCampaignAdd: {
    permissions: ['edit campaign'],
    url: '/dashboard/project/{project}/campaign/add',
  },

  projectCampaignEdit: {
    permissions: ['edit campaign'],
    url: '/dashboard/project/{project}/campaign/add/{campaign}',
  },

  projectCampaignOptimaze: {
    permissions: ['edit campaign'],
    url: '/dashboard/project/{project}/campaign/optimize',
  },

  projectConnections: {
    permissions: ['list project connections'],
    url: '/dashboard/project/{project}/connections',
  },

  projectEdit: {
    permissions: ['edit project'],
    url: '/dashboard/project/{project}',
  },

  projectFeeds: {
    permissions: ['list feeds'],
    url: '/dashboard/project/{project}/feed',
  },

  projectAssets: {
    permissions: [],
    url: '/dashboard/project/{project}/assets',
  },

  projectCategories: {
    permissions: ['edit project'],
    url: '/dashboard/project/{project}/categories',
  },

  projectBrands: {
    permissions: ['edit project'],
    url: '/dashboard/project/{project}/brands',
  },

  projectOutputFeed: {
    permissions: ['list feeds'],
    url: '/dashboard/project/{project}/feed/output/{feed}',
  },

  projectFeedsAdd: {
    permissions: ['edit feed'],
    url: '/dashboard/project/{project}/feed/new',
  },

  projectFeedsAddOutput: {
    permissions: ['edit feed'],
    url: '/dashboard/project/{project}/feed/newOutput',
  },

  projectFeedsAdded: {
    permissions: [],
    url: '/dashboard/project/{project}/feed/added',
  },

  projectNotFound: {
    url: '/dashboard/project/not-found',
  },

  projectProducts: {
    permissions: ['list products'],
    url: '/dashboard/project/{project}/products',
  },

  projectSiteHomepage: {
    permissions: ['view page'],
    url: '/dashboard/project/{project}/homepage',
    isBetaFeature: true,
  },

  projectWaitingScreen: {
    permissions: ['view page', 'chat with assistant'],
    url: '/dashboard/project/{project}/wait',
  },

  projectSitePage: {
    permissions: ['view page'],
    url: '/dashboard/project/{project}/page/{page}',
  },

  projectSitePages: {
    permissions: ['view page'],
    url: '/dashboard/project/{project}/pages',
    isBetaFeature: true,
  },

  // @TODO: Do skonczenia: DEV-135
  // projectTextAssistant: {
  //   permissions: [],
  //   url: '/dashboard/text-assistant',
  // },

  // @TODO: Do skonczenia: DEV-135
  // projectTextAssistantEditor: {
  //   permissions: [],
  //   url: '/dashboard/text-assistant/{draft}',
  // },

  projectRules: {
    permissions: ['list rules'],
    url: '/dashboard/project/{project}/rules',
  },

  projectRulesCreate: {
    permissions: ['edit rule'],
    url: '/dashboard/project/{project}/rules/new',
  },

  projectRulesEdit: {
    permissions: ['edit rule'],
    url: '/dashboard/project/{project}/rules/edit/{rule}',
  },

  projects: {
    permissions: ['list projects'],
    url: '/dashboard/project/list',
  },

  postBing: {
    permissions: [],
    url: '/dashboard/project/{project}/postBing',
  },

  users: {
    permissions: ['list users'],
    url: '/dashboard/users/list',
  },

  usersRoles: {
    permissions: ['list roles'],
    url: '/dashboard/users/roles',
  },

  usersPermissions: {
    permissions: ['list users', 'view role'],
    url: '/dashboard/users/permissions',
  },

  payments: {
    permissions: ['manage payment'],
    url: '/dashboard/payments',
  },

  paymentsBuy: {
    permissions: ['manage payment'],
    url: '/dashboard/payments/{package}/buy',
  },

  paymentsPlan: {
    permissions: ['manage payment'],
    url: '/dashboard/payments/plan',
  },

  paymentsAddProducts: {
    permissions: ['manage payment'],
    url: '/dashboard/payments/products',
  },

  paymentsConfirm: {
    permissions: ['manage payment'],
    url: '/dashboard/payments/confirm/{paymentId}',
  },

  vatGenerate: {
    permissions: ['manage payment'],
    url: '/dashboard/payments/invoices',
  },

  cardReconnection: {
    permissions: ['manage payment'],
    url: '/dashboard/payments/reconnect',
  },

  tasksList: {
    permissions: ['list tasks'],
    url: '/dashboard/tasks/list',
    isBetaFeature: true,
  },

  myTasksList: {
    permissions: ['list tasks'],
    url: '/dashboard/tasks/list/my',
    isBetaFeature: true,
  },

  projectTasksList: {
    permissions: ['list tasks'],
    url: '/dashboard/tasks/list/project',
    isBetaFeature: true,
  },

  projectTasksMessages: {
    permissions: ['list tasks'],
    url: '/dashboard/tasks/messages',
    isBetaFeature: true,
  },

  tasksCard: {
    permissions: ['view task', 'edit task'],
    url: '/dashboard/tasks/card/{taskId}',
  },

  tasksNew: {
    permissions: ['store task'],
    url: '/dashboard/tasks/new',
  },

  taskTriggersList: {
    permissions: ['list task triggers'],
    url: '/dashboard/tasks/triggers/list',
    isBetaFeature: true,
  },

  taskTriggerDuplicate: {
    permissions: ['view task trigger', 'store task trigger'],
    url: '/dashboard/tasks/triggers/duplicate/{triggerId}',
  },

  taskTriggerEdit: {
    permissions: ['view task trigger', 'edit task trigger'],
    url: '/dashboard/tasks/triggers/edit/{triggerId}',
  },

  taskTriggersNew: {
    permissions: ['store task trigger'],
    url: '/dashboard/tasks/triggers/new',
  },

  teams: {
    permissions: ['list users'],
    url: '/dashboard/teams',
  },

  adminOptions: {
    permissions: [],
    superiorPermission: SuperiorPermissionEnum.superuser,
    url: '/dashboard/admin',
  },

  adminOptionsSchema: {
    permissions: [],
    superiorPermission: SuperiorPermissionEnum.superuser,
    url: '/dashboard/admin/output-schemas',
  },

  adminReleaseInfo: {
    permissions: [],
    superiorPermission: SuperiorPermissionEnum.superuser,
    url: '/dashboard/admin/release',
  },

  dashboard: {
    permissions: ['list reports'],
    url: '/dashboard/project/{project}/dashboard', // @TODO: nomenclature: dashboard-dashboard :>
    isBetaFeature: true,
  },

  channels: {
    permissions: ['list reports'],
    url: '/dashboard/project/{project}/channels',
  },

  channelsGoogleCampaigns: {
    permissions: ['list reports'],
    url: '/dashboard/project/{project}/channels/google-campaigns',
  },

  channelsGoogleAdgroups: {
    permissions: ['list reports'],
    url: '/dashboard/project/{project}/channels/google-adgroups',
  },

  channelsGoogleKeywords: {
    permissions: ['list reports'],
    url: '/dashboard/project/{project}/channels/google-keywords',
  },

  channelsGoogleSearch: {
    permissions: ['list reports'],
    url: '/dashboard/project/{project}/channels/google-search-terms',
  },

  channelsGoogleShopping: {
    permissions: ['list reports'],
    url: '/dashboard/project/{project}/channels/google-shopping-performance',
  },

  reports: {
    permissions: ['list reports'],
    url: '/dashboard/project/{project}/reports',
  },

  reportsAdGroup: {
    permissions: ['list reports'],
    url: '/dashboard/project/{project}/reports/ad-group',
  },

  reportsCampaign: {
    permissions: ['list reports'],
    url: '/dashboard/project/{project}/reports/campaign',
  },

  reportsKeyword: {
    permissions: ['list reports'],
    url: '/dashboard/project/{project}/reports/keyword',
  },

  reportsSearch: {
    permissions: ['list reports'],
    url: '/dashboard/project/{project}/reports/search-term',
  },

  reportsShopping: {
    permissions: ['list reports'],
    url: '/dashboard/project/{project}/reports/shopping-performance',
  },
};

Object.keys(RouterConfig).map((key) => {
  const { url } = RouterConfig[key];
  const urlMask = url.replace(/{.*?}/g, maskChar);
  const areVariable = urlMask !== url;

  RouterConfig[key] = Object.assign(RouterConfig[key], { key, urlMask, areVariable });
  return RouterConfig;
});

export default RouterConfig;
